import type {
  I$W,
  IWixWindow,
  PlatformControllerFlowAPI,
  TFunction,
  ISiteApis,
} from '@wix/yoshi-flow-editor';
import type {
  DispatchStateByLocation,
  Operation,
  DispatchType,
  DispatchLocation,
} from 'root/types/businessTypes';
import { SchedulingType } from 'root/types/businessTypes';
import { DISPATCH_MODAL_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { autorun, toJS, reaction } from 'mobx';
import { LiveSiteClicksInsideDispatchModalButtonType } from '@wix/restaurants-bi';
import type { DispatchInfo, AddressInputAddress } from '../../types/businessTypes';
import type { IBIReporterService } from 'root/services/biReporterService';
import { DispatchModalForm } from './dispatchModalForm';
import {
  convertAddressInputToAddress,
  type DispatchModalStore,
} from 'root/states/DispatchModalStore';
import { BIEventsHandler, ErrorType, isValidDate } from './dispatchModalUtils';
import { dispatchState } from 'root/states/DispatchState';
import {
  BUTTON_STATES,
  getASAPString,
  NOTIFICATION_BACKGROUND_COLORS,
  STATUS_INDICATOR_COLORS,
} from '../Header/headerUtils';
import type { BindAll, IDispatchModalController } from './types';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { getDateFormat } from 'root/utils/dateTimeUtils';
import { fulfillmentPickerStore } from 'root/states/FulfillmentPickerStore';
import { getSiteLocale } from 'root/utils/siteDataUtils';
import type { CommonAddress } from '@wix/ambassador-wix-atlas-service-web/types';

export class DispatchModalController implements IDispatchModalController {
  private form!: DispatchModalForm;
  constructor(
    private $bindAll: BindAll,
    private $w: I$W,
    private flowAPI: PlatformControllerFlowAPI,
    private window: IWixWindow,
    private isEditor: boolean,
    private store: DispatchModalStore,
    private operation: Operation,
    private onSave: ({
      dispatchType,
      dispatchInfo,
      operationId,
      isLocationChanged,
    }: {
      dispatchType: DispatchType;
      dispatchInfo: DispatchInfo;
      operationId: string;
      isLocationChanged: boolean;
    }) => void,
    private biReporterService?: IBIReporterService,
    private isViewer: boolean = true,
    private locationChanged?: (address?: CommonAddress) => Promise<void>
  ) {
    const {
      flowAPI: {
        translations,
        controllerConfig: {
          wixCodeApi: { site },
        },
        errorMonitor,
        reportError,
      },
    } = this;
    const t = translations.t as TFunction;
    const timezone = site.timezone || DEFAULT_TIMEZONE;
    const locale = getSiteLocale(this.flowAPI);
    this.form = new DispatchModalForm(store, t, timezone, locale, errorMonitor, reportError);
  }

  formInit() {
    this.form.init();
  }

  init(
    onModalOpen: () => void,
    closeModal: (
      window: IWixWindow,
      data?: { dispatchType: DispatchType; dispatchInfo: DispatchInfo }
    ) => void,
    initialDispatchState?: DispatchStateByLocation
  ) {
    const t = this.flowAPI.translations.t as TFunction;
    const site = this.flowAPI.controllerConfig.wixCodeApi.site as ISiteApis;
    const isMemberLoggedIn = !!this.flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn;

    const biHandler = new BIEventsHandler(
      this.form,
      dispatchState.configuredDispatchTypes,
      this.operation,
      isMemberLoggedIn,
      this.store.schedulingType,
      this.biReporterService,
      initialDispatchState
    );

    const timezone = site.timezone || DEFAULT_TIMEZONE;
    const locale = getSiteLocale(this.flowAPI);
    this.form.init();

    autorun(() => {
      const multiStateBox = this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dispatchStateBox);
      multiStateBox.changeState(this.form.dispatchType.toLowerCase());
    });

    this.flowAPI.errorMonitor?.addBreadcrumb({
      category: 'DispatchModalController',
      message: 'init',
      data: { store: this.store },
    });

    const onDispatchTypeChange = async (dispatchType: DispatchType) => {
      biHandler.onDispatchTypeChange(dispatchType);
      await this.form.setDispatchType(dispatchType);
    };

    autorun(() => {
      fulfillmentPickerStore.setObservableProps({
        selectedDispatchType: this.form.dispatchType,
        onDispatchTypeChange,
        hasPopup: false,
        shouldConsiderAllLocations: true,
      });
    });

    reaction(
      () => !this.store.isLoading,
      () => this.changeSchedulingTypeModalState()
    );

    try {
      this.changeSchedulingTypeModalState();
      this.$bindAll({
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.multipleDelivery]: {
          deleted: () => this.isEditor && !this.store.hasMultipleDeliveryOperationsForAddress,
          collapsed: () =>
            !this.store.hasMultipleDeliveryOperationsForAddress ||
            this.store.isLoading ||
            this.form.hasError,
          hidden: () => false,
          options: () => this.form.deliveryAvailableLocations,
          value: () => this.form.deliverySelectedAvailableLocation,
          onChange: async (event: $w.Event) => {
            this.store.setLocationId(event.target.value);
            const address = this.form.address
              ? convertAddressInputToAddress(this.form.address)
              : dispatchState.dispatchInfo.address;
            await this.locationChanged?.(address);
            await this.form.changeLocation();
            this.changeSchedulingTypeModalState();
          },
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.changeLocation]: {
          deleted: () =>
            this.isEditor &&
            (!this.store.multiLocation ||
              !this.store.multiLocationForCurrentDispatchType ||
              this.store.showPickupLocationSelector),
          collapsed: () =>
            !this.store.multiLocation ||
            !this.store.multiLocationForCurrentDispatchType ||
            this.store.showPickupLocationSelector,
          hidden: () => false,
          onClick: () => {
            biHandler.onLocationChangeClick();
            this.store.setIsLocationSelected(false);
          },
          label: () => t('menu_olo.dispatchModal.changeLocation'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.buttonStateBox]: {
          currentState: () =>
            this.store.showPickupLocationSelector ? BUTTON_STATES.NEXT : BUTTON_STATES.SAVE,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.nextButton]: {
          onClick: async () => {
            await this.locationChanged?.();
            await this.form.changeLocation();
            this.changeSchedulingTypeModalState();
          },
          disabled: () => !this.store.locationId,
          label: () => t('menu_olo.dispatchModal.nextButton'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.locationNamePickupDispatch]: {
          deleted: () =>
            this.isEditor && (!this.store.multiLocation || this.store.showPickupLocationSelector),
          collapsed: () => !this.store.multiLocation || this.store.showPickupLocationSelector,
          hidden: () => false,
          text: () => this.store.pickupLocationName,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.locationLobby]: {
          deleted: () => this.isEditor && !this.store.showPickupLocationSelector,
          collapsed: () => !this.store.showPickupLocationSelector,
          hidden: () => false,
          data: () => this.store.pickupLocations,
          selectedIndex: () => this.store.getSelectedLocationIndex,
          item: (itemData: DispatchLocation, bindItem: Function, index: number) => {
            bindItem(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupLocationName, {
              text: () => itemData.name,
              collapsed: () => false,
            });
            bindItem(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupLocationAddress, {
              text: () => itemData.address?.formattedAddress,
            });
            bindItem(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.acceptOrdersText, {
              text: () => {
                return itemData.acceptOrders
                  ? t('menu_olo.dispatchModal.pickup.status.AcceptingOrders')
                  : t('menu_olo.dispatchModal.pickup.status.NotAcceptingOrders');
              },
            });
            bindItem(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.statusIndicator, {
              style: {
                backgroundColor: () =>
                  itemData.acceptOrders
                    ? STATUS_INDICATOR_COLORS.ONLINE
                    : STATUS_INDICATOR_COLORS.OFFLINE,
              },
            });
            bindItem(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupLocationPicker, {
              onSelect: (event: $w.Event) => {
                this.store.setLocationId(itemData._id, index);
                biHandler.onLocationPickerClick();
              },
            });
          },
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.NotAcceptingError]: {
          style: {
            backgroundColor: () =>
              !dispatchState.isSelectedDispatchAvailable()
                ? NOTIFICATION_BACKGROUND_COLORS.ERROR
                : NOTIFICATION_BACKGROUND_COLORS.INFO,
          },
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.NotAcceptingErrorText]: {
          text: () =>
            !dispatchState.isSelectedDispatchAvailable()
              ? t('menu_olo.dispatchModal.error.notAcceptOrders')
              : t('menu_olo.dispatchModal.info.emptyCart'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.deliverFromSubtitle]: {
          deleted: () =>
            this.isEditor &&
            (this.form.hasError ||
              !this.store.isMultiLocation ||
              !this.form.deliverySelectedAvailableLocationName),
          collapsed: () =>
            this.form.hasError ||
            !this.store.isMultiLocation ||
            !this.form.deliverySelectedAvailableLocationName ||
            this.store.isLoading,
          hidden: () => false,
          text: () => t('menu_olo.dispatchModal.delivery.from'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.deliveryFromAddress]: {
          deleted: () =>
            this.isEditor &&
            (!this.store.multiLocation || this.store.hasMultipleDeliveryOperationsForAddress),
          collapsed: () =>
            !this.store.multiLocation || this.store.hasMultipleDeliveryOperationsForAddress,
          hidden: () => false,
          text: () => this.form.deliverySelectedAvailableLocationName,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.safetyBoxForChangeLocation]: {
          deleted: () => this.isEditor,
          hidden: () => false,
          collapsed: () =>
            !this.form.address ||
            this.store.isLoading ||
            this.form.hasError ||
            (dispatchState.isSelectedDispatchAvailable() &&
              !this.store.isLocationChanged &&
              this.store.isCurrentLocationAvailableForAddress),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.safetyBoxForChangeLocationContainer]: {
          style: {
            backgroundColor: () =>
              !dispatchState.isSelectedDispatchAvailable()
                ? NOTIFICATION_BACKGROUND_COLORS.ERROR
                : NOTIFICATION_BACKGROUND_COLORS.INFO,
          },
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.safetyBoxForChangeLocationText]: {
          text: () =>
            !dispatchState.isSelectedDispatchAvailable()
              ? t('menu_olo.dispatchModal.delivery.unavailableLocation')
              : t('menu_olo.dispatchModal.info.emptyCart'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.safetyBoxForError]: {
          deleted: () =>
            this.isEditor &&
            (this.store.showPickupLocationSelector ||
              (dispatchState.isSelectedDispatchAvailable() && !this.store.isLocationChanged)),
          collapsed: () =>
            this.store.isLoading ||
            this.store.showPickupLocationSelector ||
            (dispatchState.isSelectedDispatchAvailable() && !this.store.isLocationChanged),

          hidden: () => false,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeSlots]: {
          collapsed: () =>
            this.form.hasError ||
            !this.form.isScheduleForLater ||
            this.form.dropdownTimeSlotsOptions.length === 0 ||
            this.store.isLoading,
          value: () => {
            if (!this.isViewer) {
              setTimeout(
                () =>
                  this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeSlots).resetValidityIndication(),
                0
              );
            }
            return this.form.selectedOption?.value;
          },
          options: () => this.form.dropdownTimeSlotsOptions,
          onChange: (event: $w.Event) => {
            biHandler.onTimeSlotChange();
            this.form.setSelectedOption(event.target.value);
          },
          onClick: biHandler.onTimeSlotClick,
          label: () => t('menu_olo.dispatchModal.timePicker'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dateSlots]: {
          collapsed: () => this.form.shouldCollapseDateSlotDropdown || this.store.isLoading,
          value: () => {
            if (!this.isViewer) {
              setTimeout(
                () =>
                  this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dateSlots).resetValidityIndication(),
                0
              );
            }
            return this.form.selectedDate?.toDateString();
          },
          options: () => this.form.dropdownDatesOptions,
          onChange: (event: $w.Event) => {
            biHandler.onDateSlotChange();
            this.form.setSelectedDate(new Date(event.target.value));
          },
          onClick: biHandler.onDateSlotClick,
          label: () => t('menu_olo.dispatchModal.datePicker'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.saveButton]: {
          onClick: async () => {
            const { selectedDispatchType: dispatchType, dispatchInfo } = dispatchState;
            biHandler.onSaveButtonClick(dispatchInfo.selectedTimeSlot);
            const canSubmit = await this.form.canSubmit();
            if (canSubmit) {
              const operationId = this.store.multiLocation
                ? this.store.selectedLocationOperation?.id
                : dispatchState.operationId;

              const { locationId } = this.flowAPI.controllerConfig.wixCodeApi.location.query || {};
              if (this.store.locationId !== locationId) {
                if (this.store.locationId) {
                  this.flowAPI.controllerConfig.wixCodeApi.location.queryParams.add({
                    locationId: this.store.locationId,
                  });
                }
              }
              operationId && dispatchState.setCurrentOperationId(operationId);
              dispatchState.setIsLocationSelected(true);

              operationId &&
                this.onSave({
                  dispatchType,
                  dispatchInfo,
                  operationId,
                  isLocationChanged: !!this.store.isLocationChanged,
                });
              closeModal(this.window, { dispatchType, dispatchInfo });
            }
          },
          disabled: () => this.store.isLoading || !this.form.address || this.form.hasError,
          label: () => t('menu_olo.dispatchModal.saveButton'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.addressInput]: {
          onChange: async (evt: $w.Event) => {
            await this.form.setAddress(evt.target.value as AddressInputAddress);
            if (this.form.hasError) {
              this.form.errorText === ErrorType.ADDRESS_NOT_FOUND.toString()
                ? biHandler.onAddressNotFound()
                : biHandler.onNoAvailableFulfillments();
            }
          },
          onClick: biHandler.onAddressInputClick,
          value: () => toJS(this.form.address),
          label: () => t('menu_olo.dispatchModal.delivery.to'),
          placeholder: () => t('menu_olo.dispatchModal.delivery.addressInput.placeholder'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.asapText]: {
          text: () => getASAPString(this.store.asapTimeExact, this.store.asapTimeRange, t),
          collapsed: () =>
            this.form.hasError || !(this.store.asapTimeExact || this.store.asapTimeRange),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.closeButton]: {
          onClick: () => {
            biHandler.onCloseButtonClick(dispatchState.dispatchInfo.selectedTimeSlot);
            closeModal(this.window);
          },
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.errorMessage]: {
          text: () => this.form.errorText || '',
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.errorContainer]: {
          collapsed: () =>
            (this.store.isLoading && !this.store.address) ||
            !this.form.hasError ||
            this.form.errorText === ErrorType.ADDRESS_NOT_FOUND.toString(),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.datePickerContainer]: {
          collapsed: () =>
            !this.form.address ||
            this.form.hasError ||
            (this.form.dropdownTimeSlotsOptions.length === 0 && !this.store.isLoading),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.datePicker]: {
          collapsed: () => this.store.isLoading,
          onChange: async (evt: $w.Event) => {
            await this.form.setSelectedDate(evt.target.value);
          },
          onViewChange: async (evt: $w.Event) => {
            // @ts-expect-error
            const { startDate: from, endDate: until } = evt.options ?? {};
            if (isValidDate(timezone, until)) {
              await this.store.setAvailableDates({ from, until });
            }
          },
          onClick: biHandler.onDatePickerClick,
          dateFormat: () => getDateFormat(locale),
          value: () => this.form.selectedDate,
          label: () => t('menu_olo.dispatchModal.datePicker'),
          enabledDateRanges: () => [
            ...this.store.availableDateRanges,
            ...(this.form.selectedDate
              ? [{ startDate: this.form.selectedDate, endDate: this.form.selectedDate }]
              : []),
          ],
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dateTimeSlots]: {
          options: () => this.form.dropdownTimeSlotsOptions,
          onChange: (evt: $w.Event) => this.form.setSelectedOption(evt.target.value),
          onClick: () =>
            this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
              dispatchType: this.form.dispatchType,
              isPreorder: this.form.isPreOrder,
              buttonType: LiveSiteClicksInsideDispatchModalButtonType.TIME_DROPDOWN,
              operationId: this.operation.id,
            }),
          label: () => t('menu_olo.dispatchModal.timePicker'),
          value: () => {
            if (!this.isViewer) {
              setTimeout(
                () =>
                  this.$w(
                    DISPATCH_MODAL_WIDGET_COMPONENT_IDS.dateTimeSlots
                  ).resetValidityIndication(),
                0
              );
            }
            return this.form.selectedOption?.value;
          },
          collapsed: () => this.form.dropdownTimeSlotsOptions.length === 0 || this.store.isLoading,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.schedulingPicker]: {
          collapsed: () => !this.form.canSubmitOrderForNow,
          onChange: (evt: $w.Event) => {
            biHandler.onSchedulingTypeChange(evt.target.value);
            this.form.setSchedulingType(evt.target.value);
          },
          disabled: () => this.form.hasError || this.store.isLoading,
          options: () => [
            {
              value: SchedulingType.ASAP,
              label: getASAPString(this.store.asapTimeExact, this.store.asapTimeRange, t),
            },
            {
              value: SchedulingType.ASAP_AND_FUTURE,
              label: t('menu_olo.dispatch.time.Later'),
            },
          ],
          value: () => this.form.schedulingType,
          label: () => t('menu_olo.dispatchModal.delivery.when'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeMultiState]: {
          collapsed: () =>
            this.store.isLoading ||
            this.store.showPickupLocationSelector ||
            this.form.shouldCollapseTimeMultiStateBox ||
            !dispatchState.isSelectedDispatchAvailable(),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.fulfillmentPicker]: {
          collapsed: () => !this.store.hasMoreThanOneDispatchType,
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupAddress]: {
          collapsed: () => this.store.showPickupLocationSelector,
          text: () => this.store.pickupAddress?.formattedAddress ?? '',
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.pickupHeader]: {
          text: () => t('menu_olo.dispatchModal.pickup.from'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.title]: {
          text: () => t('menu_olo.dispatchModal.headerTitle'),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.asapTime]: {
          text: () => t('menu_olo.dispatchModal.delivery.when'),
          disabled: () => this.store.isLoading,
          collapsed: () =>
            this.form.hasError || !(this.store.asapTimeExact || this.store.asapTimeRange),
        },
        [DISPATCH_MODAL_WIDGET_COMPONENT_IDS.preOrderTime]: {
          text: () => t('menu_olo.dispatchModal.delivery.when'),
          disabled: () => this.store.isLoading,
          collapsed: () =>
            this.form.hasError || (this.store.timeSlots.length === 0 && !this.store.isLoading),
        },
      });
      this.isViewer && onModalOpen();
      this.biReporterService?.reportOloLiveSiteDispatchModalOpenedBiEvent({
        isAsapDisplayed: this.form.canSubmitOrderForNow,
        availableDispatchTypes: dispatchState.configuredDispatchTypes,
        isPreorderDisplayed: this.form.isPreOrder,
        schedulingType: this.store.schedulingType,
        currentFulfillment: this.store.dispatchType,
        operationId: this.operation.id,
        isMemberLoggedIn,
        selectedSchedulingRadioButton: this.form.isSchedulingPickerDisplayed
          ? this.form.schedulingType
          : undefined,
        selectedTime: dispatchState.dispatchInfo.selectedTimeSlot,
        businessDays: this.operation.businessDaysAheadHandlingOptions,
        operationGroup: this.operation.operationGroupId,
        locationId: this.operation.locationId,
        isDefaultLocation: this.operation.locationDetails?.default,
      });
    } catch (e) {
      this.flowAPI.reportError(e as Error);
      this.flowAPI.errorMonitor?.captureException(e as Error);
    }
  }

  changeSchedulingTypeModalState() {
    const multiStateBox = this.$w(DISPATCH_MODAL_WIDGET_COMPONENT_IDS.timeMultiState);
    multiStateBox.changeState(this.store.schedulingTypeModalState);
  }
}
