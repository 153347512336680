import model from './model';
import { DispatchModalController } from './dispatchModalController';
import type { DispatchModalProps } from '../../types/widgetsProps';
import type { IWixWindow } from '@wix/yoshi-flow-editor';
import { registerToUrlChange } from 'root/utils/navigationUtils';
import type { IDispatchModalController } from './types';
import { dispatchState } from 'root/states/DispatchState';
import { DispatchModalStore } from 'root/states/DispatchModalStore';
import { FulfillmentsClient } from 'root/api/fulfillmentsClient';
import { OperationsClient } from 'root/api/operationClient';
import { initDispatchState } from 'root/states/initDispatchState';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { getPageOperationId } from 'root/utils/pageOperationUtils';
import { context } from '../../context/RootContext';
import { SPECS } from 'root/appConsts/experiments';
import { DispatchType } from 'root/types/businessTypes';
import { runInAction } from 'mobx';
import type { CommonAddress } from '@wix/ambassador-wix-atlas-service-web/types';

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  const { controllerConfig, experiments } = flowAPI;
  const { window } = controllerConfig.wixCodeApi;
  const lightboxContext = window.lightbox.getContext() || {};
  const {
    data: props,
    onModalOpen,
    closeModal,
  }: {
    data?: DispatchModalProps;
    onModalOpen: () => void;
    closeModal: (window: IWixWindow, data?: unknown) => void;
  } = lightboxContext;
  const {
    dispatchState: dispatches,
    selectedDispatchType,
    operations,
    currentOperationId,
    isLocationSelected,
    biReporterService,
    onSave,
    fedopsLogger,
    cartLocationId,
  } = props ?? {};
  let dispatchModalController: IDispatchModalController;

  const currentOperation = operations?.find((operation) => operation.id === currentOperationId);
  currentOperationId && dispatchState.setCurrentOperationId(currentOperationId);

  const { isEditor, isPreview, isViewer } = flowAPI.environment;
  const { formatAddress } = flowAPI;
  const enableMultiLocation = experiments.enabled(SPECS.enableMultiLocation);

  const initDispatchStateInEditor = async () => {
    const { reportError, httpClient, errorMonitor } = flowAPI;
    const pageOperationIdPromise = getPageOperationId(
      controllerConfig.wixCodeApi.site,
      errorMonitor
    );

    const getCurrentOperation = async () => {
      const operationsClient = new OperationsClient(httpClient);
      if (experiments.enabled(SPECS.enableOperationGroup)) {
        const operationsByGroup = await operationsClient.getOperationsByGroupId(
          pageOperationIdPromise
        );
        if (operationsByGroup?.length) {
          return (
            operationsByGroup.find((operation) => operation.locationDetails?.default) ??
            operationsByGroup[0]
          );
        }
      }

      return operationsClient.getOperation(pageOperationIdPromise);
    };

    const operationData = await getCurrentOperation();

    runInAction(() => {
      dispatchState.operations = operationData ? [operationData] : [];
    });
    const fulfillmentsClient = new FulfillmentsClient(httpClient, [operationData!]);
    const timezone = controllerConfig.wixCodeApi.site.timezone || DEFAULT_TIMEZONE;

    const state = await initDispatchState({
      fulfillmentsClient,
      operations: [operationData!],
      operationId: operationData!.id,
      timezone,
      cart: await context.CartService?.getCurrentCart(),
      fedopsLogger,
      reportError,
      supportMultiLocation: enableMultiLocation,
    });

    state &&
      dispatchState.init({
        dispatchStateByLocation: state,
        operationId: operationData!.id,
        operations: [operationData!],
      });

    dispatchState.setIsLoading(false);
    dispatchState.setIsLocationSelected(!!isLocationSelected);
    const store = new DispatchModalStore(operationData!, fulfillmentsClient);
    store.setIsLoading(true);
    store.initTimeAndDates().then(() => store.setIsLoading(false));

    dispatchModalController = new DispatchModalController(
      $bindAll,
      $w,
      flowAPI,
      window,
      isEditor,
      store,
      operationData!,
      () => {},
      biReporterService,
      !isEditor && !isPreview && isViewer
    );
  };

  const initDispatch = () => {
    if (
      !currentOperation?.locationId ||
      dispatchState.locationId !== currentOperation?.locationId
    ) {
      operations &&
        currentOperation &&
        dispatches &&
        dispatchState.init({
          dispatchStateByLocation: dispatches,
          operationId: currentOperation.id,
          operations,
          isLoading: false,
          dispatchType: selectedDispatchType,
        });
    }
  };

  initDispatch();

  if (currentOperation && dispatches && onSave) {
    const isMultiLocation = enableMultiLocation && dispatchState.isMultiLocation;

    const fulfillmentClient = new FulfillmentsClient(
      flowAPI.httpClient,
      operations ?? [],
      currentOperation.id
    );

    const store = new DispatchModalStore(
      currentOperation,
      fulfillmentClient,
      formatAddress,
      flowAPI,
      fedopsLogger,
      isMultiLocation,
      isLocationSelected,
      cartLocationId
    );

    const locationChanged = async (address?: CommonAddress) => {
      store.setIsLoading(true);
      const selectedOperation = operations?.find(
        (operation) => operation.locationId === store.locationId
      );

      selectedOperation && dispatchState.setCurrentOperationId(selectedOperation.id);
      store.setIsLocationSelected(true);
      selectedOperation && fulfillmentClient.setOperationId(selectedOperation.id);
      const isAcceptOrders = !!store.currentLocations.find(
        (location) => location.operationId === selectedOperation?.id && location.acceptOrders
      );

      if (isAcceptOrders) {
        if (dispatchState.dispatchType === DispatchType.PICKUP) {
          await store.initTimeAndDates();
        } else if (address) {
          await store.initAddress(address);
        }
      }
      store.setIsLoading(false);
    };

    store.setIsLoading(true);
    if (
      dispatchState.dispatchType === DispatchType.DELIVERY &&
      dispatchState.dispatchInfo.address
    ) {
      store.initAddress(dispatchState.dispatchInfo.address).then(() => initForm());
    } else {
      store.initTimeAndDates().then(() => initForm());
    }

    dispatchModalController = new DispatchModalController(
      $bindAll,
      $w,
      flowAPI,
      window,
      isEditor,
      store,
      currentOperation,
      onSave,
      biReporterService,
      !isEditor && !isPreview && isViewer,
      locationChanged
    );

    const initForm = () => {
      dispatchModalController.formInit();
      store.setIsLoading(false);
    };
  }

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const initialDispatchState = dispatches;
      const { wixCodeApi } = controllerConfig;
      registerToUrlChange(wixCodeApi, closeModal, window);
      if ((isEditor || isPreview) && !currentOperation && !dispatches) {
        await initDispatchStateInEditor();
      }
      dispatchModalController?.init(onModalOpen, closeModal, initialDispatchState);
    },
    exports: {},
  };
});
